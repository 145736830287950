<template>
  <div class="card" v-loading="isLoading">
    <div class="card-header">
      <div class="row">
        <h5 class="card-title mb-0 col-6">Configuration Logs</h5>
        <h5 class="text-end col-6">Updated at: {{ lastUpdateTime }}</h5>
      </div>
      <el-button class="float-end" type="primary" round size="mini" @click="refreshLogData">
        <i class="fas fa-sync-alt" /> Refresh</el-button
      >
    </div>
    <div class="card-body" style="overflow-y: scroll; height: 30vh">
      <div class="d-flex border-top border-bottom align-items-center" v-if="tableData.length === 0">
        <div class="flex-grow-1">
          <div class="text-center text-md text-muted p-2 mb-2 m-2 " style="border-radius: 5px;">
            <div class="h3 text-muted"><strong>No Data.</strong></div>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-for="item in paginatedTableData" :key="item">
          <div
            v-if="logType(item.status) === 'success' || logType(item.status) === 'danger'"
            class="d-flex align-items-start mb-2 config-log-card"
            :class="'alert-' + logType(item.status)"
            style="border-radius: 5px; cursor: pointer"
            @click="handleClickLog(item)"
          >
            <div class="flex-grow-1">
              <div class="alert mb-0" v-if="item.data.username">
                <div class="alert-icon" style="background: none;">
                  <i class="far fa-user" />
                </div>
                <div class="alert-message">
                  <strong style="font-size:0.8rem">{{ item.data.username.toUpperCase() }}</strong>
                  {{ logDescription(item.status) }}
                  <div
                    class="float-end badge me-1"
                    :class="'bg-' + logType(item.status)"
                    style="font-size:0.8rem"
                  >
                    {{ item.status }}
                  </div>
                </div>
              </div>
              <div class="alert mb-0" v-else>
                <div class="alert-icon" style="background: none;">
                  <i class="fas fa-network-wired" />
                </div>
                <div class="alert-message">
                  <strong style="font-size:0.8rem">{{ item.data.instance_name }}</strong>
                  {{ logDescription(item.status) }}
                  <div
                    class="float-end badge me-1"
                    :class="'bg-' + logType(item.status)"
                    style="font-size:0.8rem"
                  >
                    {{ item.status }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <el-popover
            placement="bottom"
            title="Sorry"
            :width="200"
            trigger="click"
            content="The configuration logs is not ready yet, please wait until the process is finished."
            v-else
          >
            <template #reference>
              <div
                class="d-flex align-items-start mb-2 config-log-card"
                :class="'alert-' + logType(item.status)"
                style="border-radius: 5px; cursor: pointer"
              >
                <div class="flex-grow-1">
                  <div class="alert mb-0" v-if="item.data.username">
                    <div class="alert-icon" style="background: none;">
                      <i class="far fa-user" />
                    </div>
                    <div class="alert-message">
                      <strong style="font-size:0.8rem">{{
                        item.data.username.toUpperCase()
                      }}</strong>
                      {{ logDescription(item.status) }}
                      <div
                        class="float-end badge me-1"
                        :class="'bg-' + logType(item.status)"
                        style="font-size:0.8rem"
                      >
                        {{ item.status }}
                      </div>
                    </div>
                  </div>
                  <div class="alert mb-0" v-else>
                    <div class="alert-icon" style="background: none;">
                      <i class="fas fa-network-wired" />
                    </div>
                    <div class="alert-message">
                      <strong style="font-size:0.8rem">{{ item.data.instance_name }}</strong>
                      {{ logDescription(item.status) }}
                      <div
                        class="float-end badge me-1"
                        :class="'bg-' + logType(item.status)"
                        style="font-size:0.8rem"
                      >
                        {{ item.status }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </el-popover>
        </div>
      </div>
    </div>
    <nav>
      <div class="row">
        <div class="col-sm-12 col-md-5">
          <span class="ms-3"
            >Show {{ lowerBound }} to {{ upperBound }} of {{ configLogs.length }} logs</span
          >
        </div>
        <div class="col-sm-12 col-md-7">
          <ul class="pagination justify-content-end me-3">
            <li class="page-item" :class="selectedPage === 1 ? 'disabled' : ''">
              <a class="page-link border-0" v-on:click="handlePagination(-1)">
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>

            <li
              v-for="page in paginationIndexes"
              :key="page"
              class="page-item"
              :class="isSelected(page) ? 'active' : ''"
            >
              <a class="page-link border-0" v-on:click="handlePaginationIndex(page)">
                {{ page }}
              </a>
            </li>

            <li
              class="page-item"
              :class="selectedPage === paginationIndexes.length ? 'disabled' : ''"
            >
              <a class="page-link border-0" v-on:click="handlePagination(1)">
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
  <el-dialog title="Configuration details" v-model="logDialogVisible" width="70%">
    <ParentsDetailsConfigLogsDialog :logData="this.selectedConfigLogResult" />
  </el-dialog>
</template>

<script>
  import ParentsDetailsConfigLogsDialog from '@/views/Parents/ParentsDetailsConfigLogsDialog'
  export default {
    name: 'ParentsDetailsConfigLogs',
    components: {
      ParentsDetailsConfigLogsDialog,
    },
    emits: ['refreshLog'],
    props: {
      isLoading: {
        type: Boolean,
        default: () => false,
      },
      configLogs: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        logDialogVisible: false,
        selectedConfigLog: null,
        selectedPage: 1,
        pageSize: 4,
        pageOptions: [
          { type: '4 instances', value: 4 },
          { type: '20 instances', value: 20 },
          { type: '50 instances', value: 50 },
        ],
      }
    },
    methods: {
      logType(status) {
        switch (status) {
          case 'SUCCESS':
            return 'success'
          case 'FAILED':
            return 'danger'
          case 'PENDING':
            return 'info'
          default:
            return 'warning'
        }
      },
      logDescription(status) {
        switch (status) {
          case 'SUCCESS':
            return ' was successfully updated.'
          case 'FAILED':
            return ' failed to be updated.'
          case 'PENDING':
            return ' is pending to be executed.'
          default:
            return ' is being executed.'
        }
      },
      handleClickLog(log) {
        switch (log.status) {
          case 'FAILED':
            this.logDialogVisible = true
            this.selectedConfigLog = log
            break
          case 'SUCCESS':
            this.logDialogVisible = true
            this.selectedConfigLog = log
            break
          default:
        }
      },
      refreshLogData() {
        this.$emit('refreshLog')
      },
      handlePagination(selectedPage) {
        this.selectedPage += selectedPage
      },
      handlePaginationIndex(selectedPage) {
        this.selectedPage = selectedPage
      },
      isSelected(pageIndex) {
        return pageIndex === this.selectedPage
      },
    },
    computed: {
      upperBound() {
        const res = this.selectedPage * this.pageSize
        return res > this.configLogs.length ? this.configLogs.length : res
      },
      lowerBound() {
        return (this.selectedPage - 1) * this.pageSize + 1
      },
      paginatedTableData() {
        const initialIdx = this.pageSize * (this.selectedPage - 1)
        return this.tableData.slice(initialIdx, initialIdx + this.pageSize)
      },
      paginationIndexes() {
        const pages = Math.ceil(this.tableData.length / this.pageSize)
        let page_arr = []
        for (let i = 1; i <= pages; i++) {
          page_arr.push(i)
        }
        return page_arr
      },
      tableData() {
        return this.configLogs
      },
      selectedConfigLogResult() {
        return this.selectedConfigLog
      },
      lastUpdateTime() {
        if (this.configLogs.length > 0) {
          return this.configLogs[0]
            ? this.$common.convertTime(this.configLogs[0].created_at.substr(11, 5)) +
                ', ' +
                String(this.configLogs[0].created_at).substr(0, 10)
            : 'Unknown'
        }
        return ''
      },
    },
    watch: {
      configLogs() {
        this.selectedPage = 1
      },
    },
  }
</script>

<style lang="scss">
  .config-log-card {
    transition: all 0.4s linear;
    &:hover {
      transform: scale(1.02, 1.02);
      transition: 0.25s;
    }
  }
  ul {
    margin-bottom: 16px !important;
  }
</style>
