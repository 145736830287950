<template>
  <div class="row">
    <div class="text-muted">
      <i class="far fa-clock" />
      Finished at {{ startTime }}
      <div class="float-end ">
        <el-switch v-model="onlyShowError" />
        Error Only
      </div>
    </div>
    <div
      class="d-flex border-top border-bottom align-items-center"
      style="min-height: 25vh;"
      v-if="tableData.length === 0"
    >
      <div class="flex-grow-1">
        <div class="text-center text-md text-muted p-2 mb-2 m-2 " style="border-radius: 5px;">
          <div class="h3 text-muted"><strong>No Data.</strong></div>
        </div>
      </div>
    </div>

    <ul class="timeline mt-2 mb-0" style="max-height: 45vh;padding-left: 32px;overflow-y: scroll;">
      <li class="timeline-item" v-for="item in tableData" :key="item">
        <div class="mb-2" :class="'alert-' + resultType(item)" style="border-radius: 5px;">
          <div class="alert-message pb-0 pb-1">
            <strong>Action: </strong>
            <strong>{{ item.domain_name }}</strong> was updated with user
            <strong>{{ item.username.toUpperCase() }}</strong>
            <div class="float-end badge me-1" :class="'bg-' + resultType(item)">
              {{ resultStatus(item) }}
            </div>

            <p><strong>Result: </strong>{{ resultDescription(item) }}</p>
          </div>
        </div>
      </li>
    </ul>
    <div class="text-muted">
      <i class="far fa-clock" />
      created at {{ endTime }}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ParentsDetailsConfigLogsDialog',
    props: {
      logData: {},
    },
    data() {
      return {
        onlyShowError: false,
      }
    },
    computed: {
      tableData() {
        if (!this.logData) return []
        if (!this.onlyShowError) return this.logData.result.result
        // filter error logs only
        return this.logData.result.result.filter(item => item.error)
      },
      startTime() {
        return this.logData
          ? this.$common.convertTime(this.logData.created_at.substr(11, 5)) +
              ', ' +
              String(this.logData.created_at).substr(0, 10)
          : 'Unknown'
      },
      endTime() {
        return this.logData
          ? this.$common.convertTime(this.logData.updated_at.substr(11, 5)) +
              ', ' +
              String(this.logData.updated_at).substr(0, 10)
          : 'Unknown'
      },
    },
    methods: {
      resultDescription(item) {
        return item.error ? item.error : 'Successfully updated.'
      },
      resultType(item) {
        return item.error ? 'danger' : 'success'
      },
      resultStatus(item) {
        return item.error ? 'ERROR' : 'COMPLETE'
      },
    },
  }
</script>

<style></style>
