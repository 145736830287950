<template>
  <el-dialog
    v-model="isVisible"
    width="50%"
    :title="isEdit ? 'Edit Customer' : 'Create Parent'"
    :before-close="handleClose"
    :show-close="false"
  >
    <div v-loading="loading">
      <el-form
        ref="customer-form"
        :rules="rules"
        :model="form"
        class="customer-form row"
        :show-message="false"
        label-position="top"
        status-icon
      >
        <el-form-item label="Domain" prop="domain" class="col-12">
          <el-select v-model="form.domain" filterable :disabled="isEdit">
            <el-option
              v-for="domain in domains"
              :key="domain.id"
              :label="domain.name"
              :value="domain.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="Host" prop="host" class="col-12 col-sm-8 col-md-9 pr-sm-2">
          <el-input
            v-model="form.host"
            placeholder="some-database-host.com"
            @keydown.space="preventDefault"
          />
        </el-form-item>

        <el-form-item label="Port" prop="port" class="col-12 col-sm-4 col-md-3 pl-sm-2">
          <el-input v-model.number="form.port" placeholder="5432" @keydown.space="preventDefault" />
        </el-form-item>

        <el-form-item label="Database" prop="database" class="col-12">
          <el-input
            v-model="form.database"
            placeholder="docq_domains"
            @keydown.space="preventDefault"
          />
        </el-form-item>

        <el-form-item label="User" prop="user" class="col-12 col-sm-6 pr-sm-2">
          <el-input v-model="form.user" placeholder="ndm" @keydown.space="preventDefault" />
        </el-form-item>

        <el-form-item label="Password" prop="password" class="col-12 col-sm-6 pl-sm-2">
          <el-input
            v-model="form.password"
            type="password"
            placeholder="**********"
            @keydown.space="preventDefault"
          />
        </el-form-item>

        <div class="col-12 mb-1">
          <el-alert
            v-if="alert.message"
            :title="alert.message"
            :type="alert.type"
            :closable="false"
            show-icon
          />
        </div>

        <el-form-item class="col-12 mt-2 dialog-buttons">
          <el-button
            class="col-12 col-sm-auto mr-0 mr-sm-3 mb-2 mb-sm-0"
            type="primary"
            @click="handleSubmit"
            >{{ !isEdit ? 'Create' : 'Update' }}</el-button
          >
          <el-button
            class="col-12 col-sm-auto mr-0 mr-sm-3 mb-2 mb-sm-0"
            type="warning"
            @click="handleTestConnection"
            >Test Connection</el-button
          >
          <el-button class="col-12 col-sm-auto" @click="handleClose">Cancel</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
  const BLANK_FORM = {
    domain: undefined,
    database: '',
    host: '',
    port: '',
    user: '',
    password: '',
  }
  export default {
    name: 'ParentsCreate',
    props: {
      customer: {
        type: Object,
        default: () => undefined,
      },
    },
    data() {
      return {
        parentId: null,
        isVisible: false,
        isEdit: false,
        loading: false,
        domains: [],
        form: BLANK_FORM,
        rules: {
          domain: [{ required: true, message: 'Domain is required.', trigger: 'blur' }],
          host: [{ required: true, message: 'Host is required.', trigger: 'blur' }],
          port: [
            { required: true, message: 'Port is required.', trigger: 'blur' },
            { type: 'number', message: 'Port must be a number.' },
          ],
          database: [{ required: true, message: 'Database is required.', trigger: 'blur' }],
          user: [{ required: true, message: 'User is required.', trigger: 'blur' }],
          password: [{ required: true, message: 'Password is required.', trigger: 'blur' }],
        },
        alert: {
          message: '',
          type: '',
        },
      }
    },
    methods: {
      handleClose(args = {}) {
        this.$emit('close', args)
        this.$refs['customer-form'].resetFields()
        this.form = BLANK_FORM
        this.alert.message = ''
      },
      async loadDomains() {
        this.domains = await this.$services.docqInstancesParents.getParentAvailability()
        this.domains.sort((a, b) => (a.name > b.name ? 1 : -1))
      },
      async handleSubmit() {
        if (await this.validateForm()) {
          try {
            this.loading = true
            const res = await this.$services.docqInstancesParents.updateParent(
              this.isEdit ? this.parentId : this.form.domain,
              this.form,
            )
            if (res) {
              this.handleClose({ refresh: true })
            } else {
              this.alert.type = 'error'
              this.alert.message =
                'Failed to update parent remote database setting, please test connection to ensure its accessibility!'
            }
          } finally {
            this.loading = false
          }
        }
      },
      async handleTestConnection() {
        if (await this.validateForm()) {
          try {
            this.loading = true
            const connected = await this.$services.docqInstancesParents.testConnection(this.form)

            if (connected) {
              this.alert.type = 'success'
              this.alert.message = 'Successfully connected to the database!'
            } else {
              this.alert.type = 'error'
              this.alert.message = 'Failed to connect to the database!'
            }
          } finally {
            this.loading = false
          }
        }
      },
      preventDefault(event) {
        event.preventDefault()
      },
      validateForm() {
        return new Promise(resolve => {
          this.$refs['customer-form'].validate(resolve)
        })
      },
    },
    mounted() {
      this.loadDomains()
    },
    watch: {
      customer: {
        handler(value) {
          this.isVisible = !!value
          if (value) {
            this.isEdit = !!value.id
            this.parentId = value.id
            if (this.isEdit) {
              this.form.domain = value.domain
              this.form.database = value.remote_database.database
              this.form.host = value.remote_database.host
              this.form.port = value.remote_database.port
              this.form.user = value.remote_database.user
            } else if (this.domains.length) {
              this.form.domain = this.domains[0].id
              this.form.database = ''
              this.form.host = ''
              this.form.port = ''
              this.form.user = ''
              this.form.password = ''
            }
          }
        },
      },
    },
  }
</script>

<style scoped></style>
