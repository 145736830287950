<template>
  <div class="row mb-0 mb-xl-1">
    <div class="col-auto d-none d-sm-block">
      <h3><strong>Parents</strong></h3>
      <span class="text-muted"
        >Create or update DocQ Parent Instances and manage their settings.</span
      >
    </div>
    <div class="col-auto ms-auto text-end mt-n1">
      <div class="row">
        <div class="col-md-6 col-xl-auto col-md-auto p-1">
          <el-input
            v-model="filters.query"
            clearable
            placeholder="Search"
            prefix-icon="fas fa-search"
            class="w-100 card"
            style="max-width: 2100px;"
          />
        </div>
        <div class="col-md-6 col-xl-auto col-md-auto p-1">
          <el-select
            v-model="filters.types"
            clearable
            placeholder="Types"
            multiple
            collapse-tags
            class="w-100 card"
          >
            <el-option
              v-for="type in types"
              :key="type.key"
              :label="type.label"
              :value="type.key"
            />
          </el-select>
        </div>
        <div class="col-md-6 col-xl-auto p-1" v-if="showBtn">
          <el-button type="primary" plain class="w-100" @click="handleCreate">
            New Parent
          </el-button>
        </div>
      </div>
    </div>
  </div>
  <ParentsTable :loading="false" :instances="filteredInstances" />
  <ParentsCreate :customer="selectedCustomer" @close="handleDialogClose" />
</template>

<script>
  import ParentsTable from '@/views/Parents/ParentsTable'
  import ParentsCreate from '@/views/Parents/ParentsCreate'
  export default {
    name: 'Parents',
    components: { ParentsTable, ParentsCreate },
    data() {
      return {
        showBtn: false,
        instancesList: [],
        selectedCustomer: undefined,
        loading: false,
        filters: {
          query: '',
          types: [],
        },
        types: [
          {
            key: 'customer',
            label: 'Customer',
          },
          {
            key: 'internal',
            label: 'Internal',
          },
          {
            key: 'partner',
            label: 'Partner',
          },
        ],
      }
    },
    computed: {
      filteredInstances() {
        const { query = '', types = [] } = this.filters
        const sanitizedQuery = query.trim().toLowerCase()
        return this.instancesList.filter(({ name, domain, type }) => {
          return (
            [name, domain].some(val => val.toLowerCase().includes(sanitizedQuery)) &&
            (!types.length || types.includes(type))
          )
        })
      },
    },
    methods: {
      async loadData() {
        try {
          this.loading = true
          this.instancesList = await this.$services.docqInstancesParents.getParentList()
        } catch (error) {
          /* show error message by alert */
          console.log(error)
        } finally {
          this.loading = false
        }
      },
      async validatePermission() {
        try {
          this.showBtn = await this.$services.users.validate('MANAGE_DOCQ_INSTANCES')
        } catch (error) {
          console.log(error)
        }
      },
      handleDialogClose(args = {}) {
        this.selectedCustomer = undefined
        if (args && args.refresh) {
          this.loadData()
        }
      },
      handleCreate() {
        this.selectedCustomer = {}
      },
    },
    mounted() {
      this.loadData()
      this.validatePermission()
    },
  }
</script>

<style scoped></style>
