<template>
  <el-dialog
    v-model="isVisible"
    width="40%"
    :title="!selectedTarget ? `Create ${contentLabel}` : `Edit ${contentLabel}`"
    :before-close="handleClose"
    :show-close="true"
  >
    <el-form
      ref="domain-form2"
      :model="form"
      :rules="rules"
      :show-message="true"
      class="row"
      label-position="top"
      status-icon
    >
      <el-form-item
        :label="contentLabel"
        prop="content"
        class="col-12"
        v-if="contentLabel === 'Domain'"
      >
        <el-select v-model="form.content" filterable class="w-100" v-if="!selectedTarget">
          <el-option v-for="item in domains" :key="item" :label="item.name" :value="item.id">
            <span style="float: left; padding-right: 1rem">{{ item.name }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.domain }}</span>
          </el-option>
        </el-select>
        <el-input disabled class="w-100" :placeholder="selectedTargetLabel" v-else></el-input>
      </el-form-item>
      <el-form-item :label="contentLabel" prop="content" class="col-12" v-else>
        <el-select v-model="form.content" filterable class="w-100" v-if="!selectedTarget">
          <el-option v-for="item in users" :key="item" :label="item" :value="item" />
        </el-select>
        <el-input disabled class="w-100" :placeholder="selectedTargetLabel" v-else></el-input>
      </el-form-item>

      <el-form-item
        :label="optionsLabel"
        prop="options"
        class="col-12"
        v-if="optionsLabel === 'Users'"
      >
        <el-select v-model="form.options" multiple filterable class="w-100">
          <el-option v-for="item in users" :key="item" :label="item" :value="item" />
        </el-select>
      </el-form-item>
      <el-form-item :label="optionsLabel" prop="options" class="col-12" v-else>
        <el-select v-model="form.options" multiple filterable class="w-100">
          <el-option v-for="item in domains" :key="item" :label="item.name" :value="item.id">
            <span style="float: left; padding-right: 1rem">{{ item.name }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.domain }}</span>
          </el-option>
        </el-select>
      </el-form-item>

      <div v-if="error" class="col-12 mb-1">
        <el-alert :title="error" type="error" :closable="false" show-icon />
      </div>

      <el-form-item class="col-12 mt-2 dialog-buttons">
        <el-button
          class="col-12 col-sm-auto mb-2 mb-sm-0 mr-0 mr-sm-2"
          type="primary"
          @click="handleSubmit"
          >Submit</el-button
        >
        <el-button class="col-12 col-sm-auto" @click="handleClose">Cancel</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
  export default {
    name: 'ParentsDetailsDomainsCreate',
    emits: ['close'],
    props: {
      showDialog: {
        type: Boolean,
        default: () => false,
      },
      contentLabel: {
        type: String,
        default: 'Domain',
      },
      optionsLabel: {
        type: String,
        default: 'Users',
      },
      domains: {
        type: Array,
        default: () => [],
      },
      users: {
        type: Array,
        default: () => [],
      },
      selectedTarget: {
        type: String,
        default: null,
      },
      targetCurOptions: {
        type: Array,
        default: () => [],
      },
      docqInstanceList: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        isVisible: false,
        loading: false,
        form: {
          content: '',
          options: [],
        },
        rules: {
          content: [
            { required: true, message: `${this.contentLabel} is required.`, trigger: 'change' },
          ],
          options: [{ required: true, message: 'Select at least one option.', trigger: 'change' }],
        },
        error: '',
      }
    },
    computed: {
      selectedTargetLabel() {
        return this.selectedTarget ? this.selectedTarget : ''
      },
    },
    methods: {
      handleClose(args) {
        this.$emit('close', args)
        this.error = ''
        this.$refs['domain-form2'].resetFields()
        this.form = {
          content: '',
          options: [],
        }
        this.isVisible = false
      },
      async handleSubmit() {
        if (await this.validateForm()) {
          try {
            this.loading = true
            this.error = ''
            const { id } = this.$route.params
            const { content, options } = this.form
            if (this.contentLabel === 'Domain') {
              const res = await this.$services.docqInstancesParents.storeRelationByDomain(
                id,
                content,
                options,
              )
              if (res) this.error = res.data[0].error + '\n ' + 'Hint: ' + res.data[0].property
            } else {
              const res = await this.$services.docqInstancesParents.storeRelationByUsername(
                id,
                content,
                options,
              )
              if (res) this.error = res.data[0].error + '\n ' + 'Hint: ' + res.data[0].property
            }

            if (!this.error) {
              this.handleClose({ refresh: true })
            }
          } finally {
            this.loading = false
          }
        }
      },
      validateForm() {
        return new Promise(resolve => {
          this.$refs['domain-form2'].validate(resolve)
        })
      },
      setUpEditMode() {
        if (this.contentLabel === 'Domain') {
          this.form.content = this.findInstanceIDbyDomain().id
          this.form.options = this.targetCurOptions
        } else {
          this.form.content = this.selectedTarget
          let arr = this.targetCurOptions
          this.form.options = []
          this.docqInstanceList.forEach(item => {
            arr.forEach((element, index) => {
              if (element === item.domain) {
                this.form.options.push(Number(item.id))
                arr.splice(index, 1)
              }
            })
          })
        }
      },
      findInstanceIDbyDomain() {
        return this.docqInstanceList.find(item => item.domain === this.selectedTarget)
      },
    },
    watch: {
      showDialog(newVal) {
        if (newVal) this.isVisible = newVal
        if (this.selectedTarget && newVal) this.setUpEditMode()
      },
    },
  }
</script>

<style scoped></style>
