<template>
  <div class="table-responsive" style="min-height:20vh">
    <table class="table table-borderless table-hover my-0">
      <thead style="position: sticky;top: 0;" class="table-header">
        <tr>
          <th>
            <a v-on:click="handleSortIconClick()" v-if="sortIcon % 3 === 0">
              {{ tableType }} <i class="fas fa-sort" />
            </a>
            <a v-on:click="handleSortIconClick()" v-if="sortIcon % 3 === 1">
              {{ tableType }} <i class="fas fa-sort-up" />
            </a>
            <a v-on:click="handleSortIconClick()" v-if="sortIcon % 3 === 2">
              {{ tableType }} <i class="fas fa-sort-down" />
            </a>
          </th>
          <th class="d-xl-table-cell text-end" v-if="permission">Actions</th>
        </tr>
      </thead>
      <tbody v-if="tableData.length !== 0">
        <tr v-for="item in paginatedTableData" :key="item">
          <td height="45" v-if="tableType === 'Domain'">
            <div class="d-flex">
              <div class="flex-shrink-0">
                <div class="bg-light rounded-2">
                  <img
                    class="logo-img"
                    :src="`https://ndmglobal.sfo2.digitaloceanspaces.com/DocQ_email/${item}.png`"
                    alt="logo"
                  />
                </div>
              </div>

              <div class="d-flex align-items-center ms-3">
                <strong class="align-baseline" style="font-size:0.95rem">{{ item }}</strong>
              </div>
            </div>
          </td>

          <td v-else>
            <div class="text-muted">{{ item }}</div>
          </td>
          <td class="d-xl-table-cell text-end" v-if="permission">
            <el-button type="success" size="mini" round @click="handleEditOnClick(item)"
              ><i class="fas fa-edit" /> Edit</el-button
            >
            <el-button type="danger" size="mini" round @click="handleRemoveOnClick(item)">
              <i class="fas fa-trash-alt"></i> Remove
            </el-button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- pagination -->
  <nav>
    <div class="row">
      <div class="col-sm-12 col-md-5">
        <span class="ms-3"
          >Show {{ lowerBound }} to {{ upperBound }} of {{ instances.length }} {{ tableType }}</span
        >
      </div>
      <div class="col-sm-12 col-md-7">
        <ul class="pagination justify-content-end me-3">
          <li class="page-item" :class="selectedPage === 1 ? 'disabled' : ''">
            <a class="page-link border-0" v-on:click="handlePagination(-1)">
              <span aria-hidden="true">&laquo;</span>
            </a>
          </li>

          <li
            v-for="page in paginationIndexes"
            :key="page"
            class="page-item"
            :class="isSelected(page) ? 'active' : ''"
          >
            <a class="page-link border-0" v-on:click="handlePaginationIndex(page)">
              {{ page }}
            </a>
          </li>

          <li
            class="page-item"
            :class="selectedPage === paginationIndexes.length ? 'disabled' : ''"
          >
            <a class="page-link border-0" v-on:click="handlePagination(1)">
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <!-- Dialog section -->
</template>

<script>
  export default {
    name: 'ParentsDetailsDomainsTable',
    emits: ['edit', 'remove'],
    props: {
      permission: {
        type: Boolean,
        default: () => false,
      },
      tableType: {
        type: String,
        default: () => 'User',
      },
      instances: {
        type: Array,
        default: () => [],
      },
      loading: {
        type: Boolean,
        default: () => false,
      },
      docqInstanceList: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        selectedTarget: null,
        selectedSource: [],
        editFormVisible: false,
        sortIcon: 0,
        selectedPage: 1,
        pageSize: 10,
        pageOptions: [
          { type: '10 instances', value: 10 },
          { type: '20 instances', value: 20 },
          { type: '50 instances', value: 50 },
        ],
      }
    },
    computed: {
      upperBound() {
        const res = this.selectedPage * this.pageSize
        return res > this.instances.length ? this.instances.length : res
      },
      lowerBound() {
        return (this.selectedPage - 1) * this.pageSize + 1
      },
      tableData() {
        /* check whether User ask to sort or not */
        /* pass the param you want to sort, and descending or ascending (-1 or 1) */
        if (this.sortIcon % 3 === 1) return this.sortedData(1)
        else if (this.sortIcon % 3 === 2) return this.sortedData(-1)
        return this.instances
      },
      paginatedTableData() {
        const initialIdx = this.pageSize * (this.selectedPage - 1)
        return this.tableData.slice(initialIdx, initialIdx + this.pageSize)
      },
      paginationIndexes() {
        const pages = Math.ceil(this.tableData.length / this.pageSize)
        let page_arr = []
        for (let i = 1; i <= pages; i++) {
          page_arr.push(i)
        }
        return page_arr
      },
    },
    methods: {
      handleSortIconClick() {
        this.sortIcon++
      },
      sortedData(sign) {
        let res = this.instances
        return res.sort((a, b) => {
          /* default is name */
          let paramA = a.toUpperCase() // ignore upper and lowercase
          let paramB = b.toUpperCase() // ignore upper and lowercase
          if (paramA < paramB) {
            return -1 * sign
          }
          if (paramA > paramB) {
            return 1 * sign
          }
          // if instance param value are same
          return 0
        })
      },
      handlePagination(selectedPage) {
        this.selectedPage += selectedPage
      },
      handlePaginationIndex(selectedPage) {
        this.selectedPage = selectedPage
      },
      isSelected(pageIndex) {
        return pageIndex === this.selectedPage
      },
      handleEditOnClick(item) {
        // raise a event Edit to parent
        this.$emit('edit', item)
      },
      handleRemoveOnClick(item) {
        this.$confirm(`Are you sure you want remove this ${this.tableType}?`, 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        })
          .then(() => {
            this.removeHandler(item)
          })
          .catch(() => {})
      },
      async removeHandler(target) {
        const { id } = this.$route.params
        if (this.tableType === 'User') {
          try {
            await this.$services.docqInstancesParents.storeRelationByUsername(id, target, [])
          } catch (e) {
            console.log(e)
          } finally {
            this.$emit('remove')
          }
        } else {
          //convert target
          const domain = this.docqInstanceList.find(elem => elem.domain === target)
          try {
            await this.$services.docqInstancesParents.storeRelationByDomain(id, domain.id, [])
          } catch (e) {
            console.log(e)
          } finally {
            this.$emit('remove')
          }
        }
      },
    },
    watch: {
      instances() {
        this.sortIcon = 0
        this.selectedPage = 1
      },
      pageSize() {
        this.selectedPage = 1
      },
    },
  }
</script>

<style scoped>
  .logo-img {
    width: auto;
    height: auto;
    max-width: 40px;
    max-height: 40px;
  }
  ul {
    margin-bottom: 16px !important;
  }
</style>
