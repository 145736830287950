<template>
  <div class="card flex-fill mb-3">
    <div class="card-header pb-0">
      <div class="row mb-0 mb-xl-1">
        <div class="col-auto d-sm-block">
          <h3 class="card-title" style="margin-bottom: 0">Users and relations</h3>
        </div>
        <div class="col-auto ms-auto text-end mt-n1">
          <div class="row">
            <div class="col-lg-12 col-xl-auto p-1">
              <el-input
                v-model="filters.query"
                clearable
                placeholder="Search"
                prefix-icon="fas fa-search"
                class="w-100 card"
                size="mini"
                style="max-width: 2100px;"
              />
            </div>
            <div class="col-lg-12 col-xl-auto p-1" v-if="permissions">
              <el-button
                v-if="true"
                type="primary"
                plain
                class="w-100"
                size="mini"
                @click="handleCreate"
                style="font-size: 12px!important"
              >
                New {{ tableType }}
              </el-button>
            </div>
            <div class="col-lg-12 col-xl-auto p-1">
              <el-button-group>
                <el-button
                  type="info"
                  size="mini"
                  :class="tableType === 'Domain' ? '' : 'is-plain'"
                  @click="handleDomainOnClick"
                  style="font-size:10px!important"
                >
                  <i class="fas fa-network-wired" />
                </el-button>
                <el-button
                  type="info"
                  size="mini"
                  :class="tableType === 'User' ? '' : 'is-plain'"
                  @click="handleUserOnClick"
                  style="font-size:10px!important"
                >
                  <i class="fas fa-users" />
                </el-button>
              </el-button-group>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ParentsDetailsDomainsTable
      :permission="permissions"
      :instances="filteredInstances"
      :table-type="currentType"
      :docq-instance-list="dialogInstanceReference"
      @edit="handleEdit"
      @remove="handleDelete"
    />
  </div>
  <ParentsDetailsDomainsCreate
    :show-dialog="dialogStatus"
    :content-label="tableType"
    :options-label="dialogOptionsLabel"
    :domains="dialogDomains"
    :users="dialogUsers"
    :selected-target="selectedTarget"
    :target-cur-options="targetCurOptions"
    :docq-instance-list="dialogInstanceReference"
    @close="handleDialogClose"
  />
</template>

<script>
  import ParentsDetailsDomainsTable from '@/views/Parents/ParentsDetailsDomainsTable'
  import ParentsDetailsDomainsCreate from '@/views/Parents/ParentsDetailsDomainsCreate'
  export default {
    name: 'ParentsDetailsDomains',
    components: { ParentsDetailsDomainsTable, ParentsDetailsDomainsCreate },
    props: {
      tableData: {
        type: Array,
        default: () => [],
      },
      permissions: {
        type: Boolean,
        default: () => false,
      },
    },
    emits: ['updateLogs'],
    data() {
      return {
        filters: {
          query: '',
        },
        relationsList: { domains: [], users: [] },
        loading: false,
        tableType: 'Domain',
        isOpenDialog: false,
        listMode: 0,
        dialogData: { domains: [], users: [] },
        selectedTarget: null,
        docqInstanceList: [],
      }
    },
    computed: {
      dialogOptionsLabel() {
        return this.tableType === 'Domain' ? 'Users' : 'Domains'
      },
      dialogStatus() {
        return this.isOpenDialog
      },
      dialogDomains() {
        return this.dialogData.domains
      },
      dialogUsers() {
        return this.dialogData.users
      },
      dialogInstanceReference() {
        return this.docqInstanceList
      },
      filteredInstances() {
        const { query = '' } = this.filters
        const sanitizedQuery = query.trim().toLowerCase()
        try {
          if (this.tableType === 'Domain') {
            return this.relationsList.domains.filter(domain => {
              return [domain].some(
                val =>
                  val.toLowerCase().includes(sanitizedQuery) &&
                  val.toLowerCase() !==
                    this.$store.state.docqParentDetails.docqParent.domain.toLowerCase(),
              )
            })
          } else {
            return this.relationsList.users.filter(username => {
              return [username].some(val => val.toLowerCase().includes(sanitizedQuery))
            })
          }
        } catch (e) {
          return []
        }
      },
      currentType() {
        return this.tableType
      },
      targetCurOptions() {
        if (!this.selectedTarget) return []
        let res = []
        if (this.tableType === 'User') {
          this.tableData.forEach(item => {
            if (
              item.username === this.selectedTarget &&
              item.domain.toLowerCase() !==
                this.$store.state.docqParentDetails.docqParent.domain.toLowerCase()
            )
              res.push(item.domain)
          })
        } else {
          this.tableData.forEach(item => {
            if (item.domain === this.selectedTarget) res.push(item.username)
          })
        }
        return res
      },
    },
    methods: {
      handleDelete() {
        this.$emit('updateLogs')
      },
      handleCreate() {
        this.isOpenDialog = true
      },
      handleEdit(item) {
        this.selectedTarget = item
        this.isOpenDialog = true
      },
      handleDialogClose(args) {
        this.isOpenDialog = false
        this.selectedTarget = null

        if (args && args.refresh) {
          // user create new domain/users, it should reload data
          this.loadData()
          // raise a event to tell parent node reload the data for config_log component
          this.$emit('updateLogs')
        }
      },
      handleUserOnClick() {
        this.tableType = 'User'
      },
      handleDomainOnClick() {
        this.tableType = 'Domain'
      },
      async loadDialogDomains() {
        try {
          this.dialogData.domains = await this.$services.docqInstancesParents.getParentAvailability()
          if (!this.dialogData.domains) this.dialogData.domains = []
        } catch (err) {
          console.log(err)
        }
      },
      async loadInstanceReference() {
        try {
          this.docqInstanceList = await this.$services.docqInstances.get()
        } catch (err) {
          console.log(err)
        }
      },
      async loadDialogUsers() {
        try {
          const { id } = this.$route.params
          this.dialogData.users = await this.$services.docqInstancesParents.getUsers(id)
          if (!this.dialogData.users) this.dialogData.users = []
        } catch (err) {
          console.log(err)
        }
      },
      async loadData() {
        this.loading = true
        await this.loadDialogDomains()
        await this.loadDialogUsers()
        await this.loadInstanceReference()
        this.loading = false
      },
    },
    watch: {
      tableData(newVal) {
        newVal.forEach(item => {
          if (!this.relationsList.domains.includes(item.domain))
            this.relationsList.domains.push(item.domain)
          if (!this.relationsList.users.includes(item.username))
            this.relationsList.users.push(item.username)
        })
      },
    },
    mounted() {
      this.loadData()
    },
  }
</script>

<style></style>
