<template>
  <div class="row mb-0 mb-xl-1">
    <div class="col-auto d-none d-sm-block">
      <h3 class="h3 mb-3">Parent Instance Details</h3>
    </div>
    <div class="col-auto ms-auto text-end mt-n1" v-if="showBtn">
      <div class="row">
        <div class="col-sm-6 col-xl-6 px-2">
          <el-button
            class="w-100"
            type="primary"
            size="small"
            style="min-width:180px;font-size:14px"
            @click="handleEdit"
          >
            <i class="fas fa-edit"></i>
            Edit
          </el-button>
        </div>
        <div class="col-sm-6 col-xl-6 px-2">
          <el-button
            class="w-100"
            type="danger"
            size="small"
            style="min-width:180px;font-size:14px"
            @click="deleteParentDialogVisible = true"
          >
            <i class="fas fa-trash-alt"></i>
            Delete
          </el-button>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 col-md-4 col-lg-4">
      <ParentsDetailsProfile :info="info" @remove="handleDelete" style="height:44vh" />
    </div>
    <div class="col-sm-12 col-md-8 col-lg-8">
      <ParentsDetailsConfigLogs
        :is-loading="loadingLogs"
        :config-logs="configLogs"
        @refreshLog="refreshLogData"
        style="height: 30vh"
      />
    </div>
    <div class="col-12">
      <ParentsDetailsDomains
        :table-data="username_domain"
        :permissions="showBtn"
        @updateLogs="loadConfigLogs"
      />
    </div>
  </div>

  <ParentsCreate :customer="selectedCustomer" @close="handleDialogClose" />
  <!-- Delete Dialog -->
  <el-dialog
    title="Delete current DocQ parent"
    v-model="deleteParentDialogVisible"
    width="50%"
    center
  >
    <h5>Are you sure you want to remove this DocQ parent? The operation cannot be undo.</h5>
    <h5 class="text-muted">Please type: <strong>DELETE THE PARENT</strong></h5>
    <el-alert
      v-if="alert.message"
      :title="alert.message"
      :type="alert.type"
      :closable="false"
      show-icon
      class="my-1"
    />
    <el-input v-model="deleteConfirmation"></el-input>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="deleteParentDialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="handleRemove">Confirm</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
  import ParentsDetailsProfile from '@/views/Parents/ParentsDetailsProfile'
  import ParentsDetailsConfigLogs from '@/views/Parents/ParentsDetailsConfigLogs'
  import ParentsDetailsDomains from '@/views/Parents/ParentsDetailsDomains'
  import ParentsCreate from '@/views/Parents/ParentsCreate'
  export default {
    name: 'ParentsDetails',
    components: {
      ParentsDetailsProfile,
      ParentsDetailsConfigLogs,
      ParentsDetailsDomains,
      ParentsCreate,
    },
    data() {
      return {
        showBtn: false,
        instanceInfo: {},
        selectedCustomer: undefined,
        loading: false,
        loadingLogs: false,
        configLogsData: [],
        deleteParentDialogVisible: false,
        deleteConfirmation: '',
        confirmationText: 'DELETE THE PARENT',
        alert: {
          message: '',
          type: '',
        },
      }
    },
    computed: {
      info() {
        return this.instanceInfo
      },
      username_domain() {
        return this.instanceInfo ? this.instanceInfo.username_domain : []
      },
      configLogs() {
        return this.configLogsData
      },
    },
    methods: {
      async loadInfo() {
        try {
          this.loading = true
          const { id } = this.$route.params
          this.instanceInfo = await this.$services.docqInstancesParents.getParent(id)
          if (!this.instanceInfo) {
            this.$message({
              type: 'error',
              message: 'DocQ Parent not found',
            })
            await this.$router.push('/parents')
          }
          this.$store.commit('loaded', this.instanceInfo)
        } catch (error) {
          console.error(error)
        } finally {
          this.loading = false
        }
      },
      async deleteParent() {
        try {
          this.loading = true
          const { id } = this.$route.params
          await this.$services.docqInstancesParents.removeParent(id)
        } catch (error) {
          console.error(error)
        } finally {
          this.loading = false
          await this.$router.push('/parents')
        }
      },
      handleDialogClose(args = {}) {
        this.selectedCustomer = undefined
        if (args && args.refresh) {
          this.loadInfo()
        }
      },
      handleEdit() {
        this.selectedCustomer = this.info
      },
      handleDelete() {
        this.deleteParent()
      },
      async loadConfigLogs() {
        try {
          this.loadingLogs = true
          const id = this.$route.params.id
          const res = await this.$services.docqInstancesConfigLogs.show(id)
          if (res) this.configLogsData = res
        } catch (err) {
          console.log(err)
        } finally {
          this.loadingLogs = false
        }
      },
      handleRemove() {
        if (this.deleteConfirmation === this.confirmationText) {
          this.deleteParentDialogVisible = false
          this.handleDelete()
        } else {
          this.alert.message = 'Please type the confirmation'
          this.alert.type = 'error'
        }
        this.deleteConfirmation = ''
      },
      refreshLogData() {
        this.loadInfo()
        this.loadConfigLogs()
      },
      async validatePermission() {
        try {
          this.showBtn = await this.$services.users.validate('MANAGE_DOCQ_INSTANCES')
        } catch (error) {
          console.log(error)
        }
      },
    },
    mounted() {
      this.loadInfo()
      this.loadConfigLogs()
      this.validatePermission()
    },
    watch: {
      deleteParentDialogVisible(newVal) {
        if (!newVal) {
          this.alert.message = ''
          this.alert.type = ''
          this.deleteConfirmation = ''
        }
      },
    },
  }
</script>

<style scoped></style>
