<template>
  <div class="card mb-3 pb-3 justify-content-center">
    <div v-if="instanceInfo !== undefined">
      <div class="card-body text-center">
        <img
          :src="
            `https://ndmglobal.sfo2.digitaloceanspaces.com/DocQ_email/${instanceInfo.domain}.png`
          "
          alt="logo"
          class="img-fluid mb-2"
          width="180"
          height="180"
        />
        <h3 class="card-title mb-0" style="font-size:1.2rem">{{ instanceInfo.name }}</h3>
        <div class="text-muted mb-2" style="font-size:1rem">{{ instanceInfo.domain }}</div>
        <div :class="`badge bg-${typeToTag(instanceInfo.type)}`" style="font-size: 1em">
          {{ instanceInfo.type.toUpperCase() }}
        </div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ParentsDetailsProfile',
    props: {
      loading: {
        type: Boolean,
        default: false,
      },
      info: {},
    },
    data() {
      return {
        instanceInfo: undefined,
      }
    },
    methods: {
      typeToTag(type) {
        switch (type) {
          case 'customer':
            return 'success'
          case 'partner':
            return 'warning'
          default:
            return 'secondary'
        }
      },
    },
    watch: {
      info(newVal) {
        this.instanceInfo = newVal
      },
      deleteParentDialogVisible(newVal) {
        if (!newVal) {
          this.alert.message = ''
          this.alert.type = ''
        }
      },
    },
  }
</script>

<style scoped></style>
